import React, { useState } from 'react';

import * as moment from 'moment';
import { Container } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { useRecoilValue } from 'recoil';

import BookingRefRow from '../components/BookingRefRow';
import CaptureTestIdRow from '../components/CaptureTestIdRow';
import LinksAll from '../components/links/LinksAll';
import LoadingMask from '../components/LoadingMask';
import LocationsRow from '../components/LocationsRow';
import LogoRow from '../components/LogoRow';
import OrderIdRow from '../components/OrderIdRow';
import ShowtimesRow from '../components/ShowtimesRow';
import TestIdRow from '../components/TestIdRow';
import TokenRow from '../components/TokenRow';
import useCheckCircuits from '../hooks/useCheckCircuitConfigs';
import useFetchCircuits from '../hooks/useFetchCircuits';
import useFetchMovies from '../hooks/useFetchMovies';
import useFetchSessions from '../hooks/useFetchSessions';
import {
  loadingState,
  isTokenValidState,
  errorsState,
} from '../services/Atoms';

import './launcher.scss';

moment.locale('gb');

const isInternalLauncher =
  window.location.host &&
  (window.location.host.toLowerCase() === 'localhost:3000' ||
    window.location.host.toLowerCase() ===
      'wmp-dti-launcher.azurewebsites.net');

const Launcher = () => {
  const loading = useRecoilValue(loadingState);
  const isTokenValid = useRecoilValue(isTokenValidState);
  const errors = useRecoilValue(errorsState);
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);

  useFetchCircuits();

  useFetchMovies();

  useFetchSessions();

  useCheckCircuits();

  return (
    <div className='launcher'>
      <Container>
        <LogoRow />
        {isTokenValid && (
          <>
            <LocationsRow />
            <ShowtimesRow />
            <TokenRow />
            <BookingRefRow />
            <OrderIdRow />
            <TestIdRow />
            <CaptureTestIdRow />
            <LinksAll />

            {isInternalLauncher && errors?.length > 0 && (
              <Toast
                show={showA}
                onClose={toggleShowA}
                className='w-75 position-absolute bottom-0 mb-3'
              >
                <Toast.Header>
                  <strong className='me-auto'>AppDot Config Errors</strong>
                </Toast.Header>
                <Toast.Body>
                  {errors.map((e) => (
                    <div key={e} className='p-2'>
                      {e}
                    </div>
                  ))}
                </Toast.Body>
              </Toast>
            )}
          </>
        )}
      </Container>
      {loading && <LoadingMask />}
    </div>
  );
};

export default Launcher;
