import React from 'react';

import classnames from 'classnames';
import { Row, Col, Form } from 'react-bootstrap';
import { useRecoilValue, useRecoilState } from 'recoil';

import {
  externalOrderIdState,
  showOrderExternalIdState,
} from '../services/Atoms';

const OrderIdRow = () => {
  const [externalOrderId, setExternalOrderId] =
    useRecoilState(externalOrderIdState);
  const showOrderExternalId = useRecoilValue(showOrderExternalIdState);
  return (
    <Row
      className={classnames(
        'order-external-id-ref-row',
        showOrderExternalId || 'hidden'
      )}
    >
      <Col>
        <Form.Group controlId='Form.OrderExternalId'>
          <Form.Label>Order External Id</Form.Label>
          <Form.Control
            type='text'
            value={externalOrderId}
            placeholder='paste order external id (i.e. from the confirmation url)'
            onChange={(e) => setExternalOrderId(e.currentTarget.value)}
            data-nr-qb-orderexternalid=''
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default OrderIdRow;
