import { useRecoilValue } from 'recoil';

import { sourceOptions, deviceOptions } from '../Constants';
import {
  loyaltyState,
  circuitState,
  sourceState,
  cinemaState,
  addCinemaIdState,
  addCircuitIdState,
  showBookingRefState,
  showTestIdState,
  showTestCaptureIdState,
  tokenState,
  bookingRefState,
  testIdState,
  testCaptureIdState,
} from '../services/Atoms';
import { buildQueryString } from '../services/QueryStringHelper';

const useGetQueryString = (hasPreviousParam = false) => {
  const loyalty = useRecoilValue(loyaltyState);
  const source = useRecoilValue(sourceState);
  const circuit = useRecoilValue(circuitState);
  const cinema = useRecoilValue(cinemaState);
  const addCinemaId = useRecoilValue(addCinemaIdState);
  const addCircuitId = useRecoilValue(addCircuitIdState);
  const showBookingRef = useRecoilValue(showBookingRefState);
  const showTestId = useRecoilValue(showTestIdState);
  const showTestCaptureId = useRecoilValue(showTestCaptureIdState);
  const token = useRecoilValue(tokenState);
  const bookingRef = useRecoilValue(bookingRefState);
  const testId = useRecoilValue(testIdState);
  const testCaptureId = useRecoilValue(testCaptureIdState);

  return buildQueryString(
    {
      source: sourceOptions[source],
      device: deviceOptions[source],
      cinemaId: addCinemaId && cinema && cinema.id,
      circuitId: addCircuitId && circuit && circuit.id,
      bookingRef: showBookingRef && bookingRef,
      requestData: loyalty && token,
      testId: showTestId && testId,
      testCaptureId: showTestCaptureId && testCaptureId,
    },
    hasPreviousParam
  );
};
export default useGetQueryString;
