import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import backend from '../services/RestUtilities';

const VersionRow = ({ envConfig }) => {
  const [env, setEnv] = useState(null);
  useEffect(() => {
    const fetchVersion = async () => {
      const url = `https://${envConfig.url}`;
      const response = await backend.get(url);
      const env = { ...envConfig };
      if (response.ok) {
        env.result = response.content;
      } else {
        env.result = 'Error';
      }
      setEnv(env);
    };
    if (!env) {
      fetchVersion();
    }
  }, [env, envConfig]);
  if (!env) return null;
  const splitResult = env.result.split('+');
  const splitBuild = splitResult[0].split('_');
  const time = splitBuild[3];
  return (
    <tr key={env.url} className='compact'>
      <td>{env.name}</td>
      <td>{env.env}</td>
      <td className='branch '>{splitBuild[0]}</td>
      <td className='type '>{splitBuild[1]}</td>
      <td className='date '>{splitBuild[2]}</td>
      <td className='time '>
        {time
          ? `${time.substring(0, 2)}:${time.substring(time.length - 2)}`
          : ''}
      </td>
      <td className='commit fst-italic '>{splitResult[1]}</td>
    </tr>
  );
};
VersionRow.propTypes = {
  envConfig: PropTypes.object.isRequired,
};
export default VersionRow;
