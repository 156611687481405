import { useRecoilValue } from 'recoil';

import { boostEnvOptions } from '../Constants';
import { circuitState, envState } from '../services/Atoms';

const useHostName = () => {
  const circuit = useRecoilValue(circuitState);
  const env = useRecoilValue(envState);
  const hostname =
    circuit && env !== 'LOCAL' && env !== 'DEV' && env !== 'STAGING'
      ? circuit.startTicketingUrl
      : boostEnvOptions[env];
  return hostname;
};
export default useHostName;
