import React from 'react';

import * as moment from 'moment';
import { Row, Col, Form } from 'react-bootstrap';
import { useRecoilValue, useRecoilState } from 'recoil';

import { allScreenKey } from '../Constants';
import {
  moviesState,
  movieState,
  sessionState,
  sessionsState,
  screensState,
  screenIdState,
} from '../services/Atoms';

const ShowtimesRow = () => {
  const movies = useRecoilValue(moviesState);
  const screens = useRecoilValue(screensState);
  const [movie, setMovie] = useRecoilState(movieState);
  const sessions = useRecoilValue(sessionsState);
  const [session, setSession] = useRecoilState(sessionState);
  const [screenId, setScreenId] = useRecoilState(screenIdState);

  const handleMovieChange = async (e) => {
    const movieId = parseInt(e.target.value, 10);
    const movie = movies.find((x) => x.filmId === movieId);
    setMovie(movie);
  };

  const handleSessionChange = (e) => {
    const sessionId = parseInt(e.target.value, 10);
    const session = sessions.find((x) => x.id === sessionId);
    setSession(session);
  };

  const handleScreenChange = (e) => {
    const screenId = e.target.value;
    let firstSession;
    if (screenId !== allScreenKey) {
      firstSession = sessions.find((x) => x.screen === screenId);
    } else {
      firstSession = sessions[0];
    }
    setScreenId(screenId);
    setSession(firstSession);
  };

  const renderMovieOptions = () => {
    if (movies.length === 0) return null;
    return movies.map((c) => (
      <option key={c.filmId} value={`${c.filmId}`} data-nr-qb-filmname=''>
        {c.title}
      </option>
    ));
  };

  const renderSessionOptions = () => {
    if (!sessions.length) return;
    let filteredSessions = sessions;

    if (screenId !== allScreenKey) {
      filteredSessions = sessions.filter((x) => {
        return x.screen === screenId;
      });
    }

    if (filteredSessions.length === 0) return null;

    return filteredSessions.map((x) => (
      <option key={x.id} value={`${x.id}`} data-nr-qb-sessionname=''>
        {moment(x.date).locale('en').format('Do MMM h:mm a')}{' '}
        {x.seatsSelection ? '(RS)' : ''}
      </option>
    ));
  };

  const renderScreenOptions = () => {
    if (!screens.length) return;
    let renderedScreens = screens.map((x) => (
      <option key={x} value={x} data-nr-qb-screenname=''>
        {x}
      </option>
    ));
    let allOption = (
      <option
        key={allScreenKey}
        value={`${allScreenKey}`}
        data-nr-qb-screenname=''
      >
        All
      </option>
    );
    renderedScreens.splice(0, 0, allOption);

    return renderedScreens;
  };

  return (
    <Row className='my-3'>
      {movies.length ? (
        <Col sm={4}>
          <Form.Group controlId='Form.ControlsSelect'>
            <Form.Label>Movie</Form.Label>
            <Form.Control
              as='select'
              value={movie ? movie.id : ''}
              onChange={handleMovieChange}
              data-nr-qb-filmbtn=''
            >
              {renderMovieOptions()}
            </Form.Control>
          </Form.Group>
        </Col>
      ) : null}
      {screens && (
        <Col sm={4}>
          <Form.Group controlId='Form.ControlsSelect'>
            <Form.Label>Screen</Form.Label>
            <Form.Control
              as='select'
              value={screenId}
              onChange={handleScreenChange}
              data-nr-qb-screenbtn=''
            >
              {renderScreenOptions()}
            </Form.Control>
          </Form.Group>
        </Col>
      )}
      {sessions && (
        <Col sm={4}>
          <Form.Group controlId='Form.ControlsSelect'>
            <Form.Label>Session</Form.Label>
            <Form.Control
              as='select'
              value={session ? session.id : ''}
              onChange={handleSessionChange}
              data-nr-qb-sessionbtn=''
            >
              {renderSessionOptions()}
            </Form.Control>
          </Form.Group>
        </Col>
      )}
    </Row>
  );
};

export default ShowtimesRow;
