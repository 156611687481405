import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import EnvVersionsTable from '../components/EnvVersionsTable';
import { devEnvs, uatEnvs, liveEnvs, stagingEnvs } from '../Constants';
const Launcher = () => {
  const navigate = useNavigate();

  return (
    <div className='launcher'>
      <Container>
        <Row className='logo-row'>
          <Col
            sm={6}
            className='text-start image-container d-flex align-items-center'
          >
            <h1>Versions</h1>
          </Col>
          <Col
            sm={6}
            className='text-start image-container d-flex align-items-center justify-content-end'
          >
            <Button
              type='submit'
              variant={'primary'}
              size='sm'
              className='mb-2'
              onClick={() => navigate('/')}
            >
              Back Home
            </Button>
          </Col>
        </Row>
        <EnvVersionsTable envVersions={devEnvs} groupName={'Dev'} />
        <EnvVersionsTable envVersions={uatEnvs} groupName={'Uat'} />
        <EnvVersionsTable envVersions={liveEnvs} groupName={'Live'} />
        <EnvVersionsTable envVersions={stagingEnvs} groupName={'Staging'} />
      </Container>
    </div>
  );
};

export default Launcher;
