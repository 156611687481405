import React from 'react';

import classnames from 'classnames';
import { Row, Col, Form } from 'react-bootstrap';
import { useRecoilValue, useRecoilState } from 'recoil';

import { showTestIdState, testIdState } from '../services/Atoms';

const TestIdRow = () => {
  const [testId, setTestId] = useRecoilState(testIdState);
  const showTestId = useRecoilValue(showTestIdState);
  return (
    <Row
      className={classnames(
        'order-external-id-ref-row',
        showTestId || 'hidden'
      )}
    >
      <Col>
        <Form.Group controlId='Form.TestId'>
          <Form.Label>Test ID</Form.Label>
          <Form.Control
            type='text'
            value={testId}
            placeholder='enter a TestID like Vista.Everyman.ExistingTest'
            onChange={(e) => setTestId(e.currentTarget.value)}
            data-nr-qb-testid=''
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default TestIdRow;
