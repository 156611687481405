export function buildQueryString(queryObject, hasPreviousParam) {
  const params = createArray(queryObject);
  const query = new URLSearchParams(params).toString();
  if (hasPreviousParam) {
    return query.length ? `&${query}` : '';
  } else {
    return query.length ? `?${query}` : '';
  }
}

function createArray(queryObject) {
  let params = [];
  Object.entries(queryObject).forEach((entry) => {
    const [key, value] = entry;
    if (value !== undefined && value !== null && value != '') {
      params.push([key, value.toString()]);
    }
  });
  return params;
}
