import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { boostEnvOptions } from '../Constants';
import {
  envState,
  loadingState,
  accessTokenState,
  circuitState,
  cinemaState,
  showStartTicketingActionButtonState,
  showStartConcessionsActionButtonState,
  showStartGiftCardsActionButtonState,
  showStartPhysicalGiftCardsActionButtonState,
  showStartGiftMembershipActionButtonState,
  showStartVouchersActionButtonState,
  showStartSeasonPassActionButtonState,
  movieState,
  moviesState,
  sessionsState,
} from '../services/Atoms';
import backend from '../services/RestUtilities';

const useFetchMovies = () => {
  const accessToken = useRecoilValue(accessTokenState);
  const env = useRecoilValue(envState);
  const setLoading = useSetRecoilState(loadingState);
  const circuit = useRecoilValue(circuitState);
  const cinema = useRecoilValue(cinemaState);
  const setMovie = useSetRecoilState(movieState);
  const setMovies = useSetRecoilState(moviesState);
  const setSessions = useSetRecoilState(sessionsState);
  const setShowStartTicketingActionButton = useSetRecoilState(
    showStartTicketingActionButtonState
  );
  const setShowStartConcessionsActionButton = useSetRecoilState(
    showStartConcessionsActionButtonState
  );
  const setShowStartGiftCardsActionButton = useSetRecoilState(
    showStartGiftCardsActionButtonState
  );
  const setShowStartPhysicalGiftCardsActionButton = useSetRecoilState(
    showStartPhysicalGiftCardsActionButtonState
  );
  const setShowStartGiftMembershipActionButton = useSetRecoilState(
    showStartGiftMembershipActionButtonState
  );
  const setShowStartVouchersActionButton = useSetRecoilState(
    showStartVouchersActionButtonState
  );
  const setShowStartSeasonPassActionButton = useSetRecoilState(
    showStartSeasonPassActionButtonState
  );

  useEffect(() => {
    const fetchMovies = async () => {
      if (!circuit || !cinema) return;
      setLoading(true);
      let platformVersionToUse;
      if (env === 'LOCAL' || env === 'DEV') {
        let splitString = circuit.platformVersion.split('-');
        platformVersionToUse = `${splitString[0]}-${splitString[1]}-Dev`;
      } else {
        platformVersionToUse = circuit.platformVersion;
      }
      const response = await backend.get(
        `${boostEnvOptions[env]}/api/data/Movies/${cinema.id}?platformVersion=${platformVersionToUse}`
      );
      if (response.ok) {
        const movies = response.content;
        setMovies(movies);
        setMovie(movies.length ? movies[0] : null);
        setSessions([]);
        setShowStartTicketingActionButton(false);
        setShowStartConcessionsActionButton(true);
        setShowStartGiftCardsActionButton(true);
        setShowStartPhysicalGiftCardsActionButton(true);
        setShowStartGiftMembershipActionButton(true);
        setShowStartVouchersActionButton(true);
        setShowStartSeasonPassActionButton(true);
      }
      setLoading(false);
    };
    if (cinema) {
      fetchMovies();
    }
  }, [
    cinema,
    circuit,
    env,
    accessToken,
    setMovies,
    setMovie,
    setSessions,
    setLoading,
    setShowStartTicketingActionButton,
    setShowStartConcessionsActionButton,
    setShowStartGiftCardsActionButton,
    setShowStartPhysicalGiftCardsActionButton,
    setShowStartGiftMembershipActionButton,
    setShowStartVouchersActionButton,
    setShowStartSeasonPassActionButton,
  ]);
};
export default useFetchMovies;
