import React from 'react';

import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';

import VersionRow from '../components/VersionRow';

const EnvVersionsTable = ({ envVersions, groupName }) => {
  return (
    <div>
      <h3>{groupName}</h3>
      <Table striped bordered hover className='mb-3'>
        <thead>
          <tr>
            <th>Product</th>
            <th>Env/Genosha</th>
            <th>Build/Branch</th>
            <th>Type</th>
            <th>Date</th>
            <th>Time</th>
            <th>Commit</th>
          </tr>
        </thead>
        <tbody>
          {envVersions.length > 0 &&
            envVersions.map((v) => <VersionRow key={v.url} envConfig={v} />)}
        </tbody>
      </Table>
    </div>
  );
};
EnvVersionsTable.propTypes = {
  envVersions: PropTypes.array.isRequired,
  groupName: PropTypes.string.isRequired,
};
export default EnvVersionsTable;
