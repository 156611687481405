import React from 'react';

import classnames from 'classnames';
import { Row, Col, Form } from 'react-bootstrap';
import { useRecoilValue, useRecoilState } from 'recoil';

import { tokenState, loyaltyState } from '../services/Atoms';

const TokenRow = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const loyalty = useRecoilValue(loyaltyState);
  return (
    <Row className={classnames('loyalty-row my-3', loyalty || 'hidden')}>
      <Col>
        <Form.Group controlId='Form.LoyaltyRequestData'>
          <Form.Label>Request Data</Form.Label>
          <Form.Control
            type='text'
            value={token}
            placeholder='paste encrypted requestData'
            onChange={(e) => setToken(e.currentTarget.value)}
            data-nr-qb-loyaltyrequestdata=''
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default TokenRow;
