import { atom } from 'recoil';

export const errorsState = atom({
  key: 'errors',
  default: null,
});
export const circuitsState = atom({
  key: 'circuits',
  default: [],
});
export const circuitState = atom({
  key: 'circuit',
  default: null,
});
export const cinemasState = atom({
  key: 'cinemas',
  default: [],
});
export const cinemaState = atom({
  key: 'cinema',
  default: null,
});
export const moviesState = atom({
  key: 'movies',
  default: [],
});
export const movieState = atom({
  key: 'movie',
  default: null,
});
export const sessionsState = atom({
  key: 'sessions',
  default: [],
});
export const sessionState = atom({
  key: 'session',
  default: null,
});
export const screensState = atom({
  key: 'screens',
  default: null,
});
export const screenIdState = atom({
  key: 'screenId',
  default: '',
});
export const tokenState = atom({
  key: 'token',
  default: '',
});
export const loadingState = atom({
  key: 'loading',
  default: false,
});
export const envState = atom({
  key: 'env',
  default: 'UAT',
});
export const sourceState = atom({
  key: 'source',
  default: '',
});
export const loyaltyState = atom({
  key: 'loyalty',
  default: false,
});
export const bookingRefState = atom({
  key: 'bookingRef',
  default: '',
});
export const externalOrderIdState = atom({
  key: 'externalOrderId',
  default: '',
});
export const testIdState = atom({
  key: 'testId',
  default: '',
});
export const testCaptureIdState = atom({
  key: 'testCaptureId',
  default: '',
});
export const addCinemaIdState = atom({
  key: 'addCinemaId',
  default: false,
});
export const addCircuitIdState = atom({
  key: 'addCircuitId',
  default: false,
});
export const showBookingRefState = atom({
  key: 'showBookingRef',
  default: false,
});
export const showOrderExternalIdState = atom({
  key: 'showOrderExternalId',
  default: false,
});
export const showTestIdState = atom({
  key: 'showTestId',
  default: false,
});
export const showTestCaptureIdState = atom({
  key: 'showTestCaptureId',
  default: false,
});
export const showStartTicketingActionButtonState = atom({
  key: 'showStartTicketingActionButton',
  default: false,
});
export const showStartConcessionsActionButtonState = atom({
  key: 'showStartConcessionsActionButton',
  default: false,
});
export const showStartGiftCardsActionButtonState = atom({
  key: 'showStartGiftCardsActionButton',
  default: false,
});
export const showStartPhysicalGiftCardsActionButtonState = atom({
  key: 'showStartPhysicalGiftCardsActionButton',
  default: false,
});
export const showStartGiftMembershipActionButtonState = atom({
  key: 'showStartGiftMembershipActionButton',
  default: false,
});
export const showStartVouchersActionButtonState = atom({
  key: 'showStartVouchersActionButton',
  default: false,
});
export const showStartSeasonPassActionButtonState = atom({
  key: 'showStartSeasonPassActionButton',
  default: false,
});
export const accessTokenState = atom({
  key: 'accessToken',
  default: localStorage.getItem('accessToken') || '',
});
export const isTokenValidState = atom({
  key: 'isTokenValid',
  default: false,
});
