import React from 'react';

import { Row, Col, Form } from 'react-bootstrap';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { allScreenKey } from '../Constants';
import {
  circuitsState,
  circuitState,
  cinemasState,
  cinemaState,
  sessionsState,
  screensState,
  screenIdState,
} from '../services/Atoms';

const LocationsRow = () => {
  const circuits = useRecoilValue(circuitsState);
  const [circuit, setCircuit] = useRecoilState(circuitState);
  const [cinemas, setCinemas] = useRecoilState(cinemasState);
  const [cinema, setCinema] = useRecoilState(cinemaState);
  const setSessions = useSetRecoilState(sessionsState);
  const setScreens = useSetRecoilState(screensState);
  const setScreenId = useSetRecoilState(screenIdState);

  const renderCircuitOptions = () => {
    if (circuits.length === 0) return null;
    var mutable = [...circuits];
    mutable.sort(function (a, b) {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    let circuitOptions = mutable.map((c) => (
      <option
        key={c.boxOfficeCircuitId}
        value={`${c.id}`}
        data-nr-qb-regionname=''
      >
        {c.name}
      </option>
    ));
    return circuitOptions;
  };

  const renderCinemaOptions = () => {
    if (cinemas.length === 0) return null;
    return cinemas.map((c) => (
      <option key={c.id} value={`${c.id}`} data-nr-qb-theatrename=''>
        {c.name}
      </option>
    ));
  };

  const handleCircuitChange = (e) => {
    const circuitId = parseInt(e.target.value, 10);
    const circuit = circuits.find((x) => x.id === circuitId);
    let cinema = null;
    const cinemas = circuit ? circuit.cinemas : [];
    if (cinemas.length > 0) {
      const cinemaId = cinemas[0].id;
      cinema = cinemas.find((x) => x.id === cinemaId);
    }
    setCircuit(circuit);
    setCinemas(cinemas);
    setCinema(cinema);
    setSessions([]);
    setScreens([]);
    setScreenId(allScreenKey);
  };

  const handleCinemaChange = async (e) => {
    const cinemaId = parseInt(e.target.value, 10);
    const cinema = cinemas.find((x) => x.id === cinemaId);
    setCinema(cinema);
  };

  return (
    <Row className='selectors-row mt-3'>
      <Col sm={6}>
        <Form.Group controlId='Form.ControlsSelect'>
          <Form.Label>Circuit</Form.Label>
          <Form.Control
            as='select'
            value={circuit ? circuit.id : ''}
            onChange={handleCircuitChange}
            placeholder='--Select--'
            data-nr-qb-regionbtn=''
          >
            {renderCircuitOptions(circuits)}
          </Form.Control>
        </Form.Group>
      </Col>
      {cinemas && (
        <Col sm={6}>
          <Form.Group controlId='Form.ControlsSelect'>
            <Form.Label>Cinema</Form.Label>
            <Form.Control
              as='select'
              value={cinema?.id ? cinema.id : ''}
              onChange={handleCinemaChange}
              placeholder='Select a cinema'
              data-nr-qb-theatrebtn=''
            >
              {renderCinemaOptions()}
            </Form.Control>
          </Form.Group>
        </Col>
      )}
    </Row>
  );
};

export default LocationsRow;
