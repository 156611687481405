import React from 'react';

export const allScreenKey = '-1';

export const boostEnvOptions = {
  LOCAL: 'https://localhost:5001',
  DEV: 'https://wmp-dti-dev-client.azurewebsites.net',
  UAT: 'https://wmp-dti-uat-client.azurewebsites.net',
  STAGING: 'https://wmp-dti-live-client-staging.azurewebsites.net',
  LIVE: 'https://wmp-dti-live-client.azurewebsites.net',
};

export const sourceOptions = {
  NONE: '',
  WEB: 'web',
  IOS: 'app',
  ANDROID: 'app',
  VOICE: 'voice.alexa',
};

export const deviceOptions = {
  NONE: '',
  WEB: '',
  IOS: 'ios',
  ANDROID: 'android',
  VOICE: '',
};

export const getEnvNightRiderAttr = (env) => {
  switch (env) {
    case 'LOCAL':
      return { 'data-nr-qb-env-local': '' };
    case 'DEV':
      return { 'data-nr-qb-env-dev': '' };
    case 'UAT':
      return { 'data-nr-qb-env-uat': '' };
    case 'STAGING':
      return { 'data-nr-qb-env-staging': '' };
    case 'LIVE':
      return { 'data-nr-qb-env-live': '' };
    default:
      return '';
  }
};

export const getSourceNightRiderAttr = (source) => {
  switch (source) {
    case 'WEB':
      return { 'data-nr-qb-source-web': '' };
    case 'IOS':
      return { 'data-nr-qb-source-ios': '' };
    case 'ANDROID':
      return { 'data-nr-qb-source-android': '' };
    case 'VOICE':
      return { 'data-nr-qb-source-voice': '' };
    default:
      return '';
  }
};

export const renderLink = (url, text) => {
  return (
    <div className='text-center my-1'>
      <a
        className='start'
        href={url}
        data-nr-qb-submit=''
        rel='noopener noreferrer'
        target='_blank'
      >
        <span>{text}</span>
      </a>
    </div>
  );
};

export const devEnvs = [
  {
    name: 'Boost',
    env: 'Dev',
    url: 'wmp-dti-dev-client.azurewebsites.net/api/data/Version',
  },
  {
    name: 'QuickSilver',
    env: 'Dev',
    url: 'quicksilver-api-dev.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'Dev',
    url: 'unified-api-dev.azurewebsites.net/version',
  },
  {
    name: 'Iceman',
    env: 'Dev',
    url: 'wmp-mobileapi-dev.azurewebsites.net/version',
  },
  {
    name: 'NightCrawler',
    env: 'Dev',
    url: 'nightcrawler-dev-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Sage',
    env: 'Dev',
    url: 'sage-dev-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'Dev',
    url: 'cyclops-dev-functions.azurewebsites.net/api/version',
  },
];
export const uatEnvs = [
  {
    name: 'Boost',
    env: 'Uat',
    url: 'wmp-dti-uat-client.azurewebsites.net/api/data/Version',
  },
  {
    name: 'QuickSilver',
    env: 'Uat',
    url: 'quicksilver-api-uat.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'Uat',
    url: 'unified-api-uat.azurewebsites.net/version',
  },
  {
    name: 'Banshee',
    env: 'Uat',
    url: 'banshee-uat-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Legion',
    env: 'Uat',
    url: 'legion-uat-functions.azurewebsites.net/api/getversion',
  },
  {
    name: 'NightCrawler',
    env: 'Uat',
    url: 'nightcrawler-uat-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Sage',
    env: 'Uat',
    url: 'sage-uat-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'Uat',
    url: 'cyclops-uat-functions.azurewebsites.net/api/version',
  },
];
export const liveEnvs = [
  {
    name: 'Boost',
    env: 'US live',
    url: 'wmp-dti-live-client.azurewebsites.net/api/data/Version',
  },
  {
    name: 'Boost',
    env: 'FR live',
    url: 'boost-fr.azurewebsites.net/api/data/Version',
  },
  {
    name: 'QuickSilver',
    env: 'US Live',
    url: 'quicksilver-api-live.azurewebsites.net/version',
  },
  {
    name: 'QuickSilver',
    env: 'FR Live',
    url: 'quicksilver-fr.azurewebsites.net/version',
  },
  {
    name: 'QuickSilver',
    env: 'CGR Live',
    url: 'quicksilver-cgr.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'US Live',
    url: 'unified-api-live.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'FR Live',
    url: 'cypher-fr.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'CGR Live',
    url: 'cypher-cgr.azurewebsites.net/version',
  },
  {
    name: 'Iceman',
    env: 'US Live',
    url: 'wmp-prime-live.azurewebsites.net/version',
  },
  {
    name: 'Iceman',
    env: 'FR Live',
    url: 'iceman-fr.azurewebsites.net/version',
  },
  {
    name: 'Iceman',
    env: 'CGR Live',
    url: 'iceman-cgr.azurewebsites.net/version',
  },
  {
    name: 'Banshee',
    env: 'Live',
    url: 'banshee-live-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Legion',
    env: 'Live',
    url: 'legion-live-functions.azurewebsites.net/api/getversion',
  },
  {
    name: 'NightCrawler',
    env: 'US Live',
    url: 'nightcrawler-live-functions.azurewebsites.net/api/version',
  },
  {
    name: 'NightCrawler',
    env: 'FR Live',
    url: 'nightcrawler-fr-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Sage',
    env: 'Live',
    url: 'sage-live-function.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'US Live',
    url: 'cyclops-live-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'FR Live',
    url: 'cyclops-fr-functions.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'CGR Live',
    url: 'cyclops-cgr-functions.azurewebsites.net/api/version',
  },
];
export const stagingEnvs = [
  {
    name: 'Boost',
    env: 'US Staging',
    url: 'wmp-dti-live-client-staging.azurewebsites.net/api/data/Version',
  },
  {
    name: 'Boost',
    env: 'FR Staging',
    url: 'boost-fr-staging.azurewebsites.net/api/data/Version',
  },
  {
    name: 'QuickSilver',
    env: 'US Staging',
    url: 'quicksilver-api-live-staging.azurewebsites.net/version',
  },
  {
    name: 'QuickSilver',
    env: 'FR Staging',
    url: 'quicksilver-fr-staging.azurewebsites.net/version',
  },
  {
    name: 'QuickSilver',
    env: 'CGR Staging',
    url: 'quicksilver-cgr-staging.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'US Staging',
    url: 'unified-api-live-staging.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'FR Staging',
    url: 'cypher-fr-staging.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'CGR Staging',
    url: 'cypher-cgr-staging.azurewebsites.net/version',
  },
  {
    name: 'Iceman',
    env: 'US Staging',
    url: 'wmp-prime-live-staging.azurewebsites.net/version',
  },
  {
    name: 'Iceman',
    env: 'FR Staging',
    url: 'iceman-fr-staging.azurewebsites.net/version',
  },
  {
    name: 'Iceman',
    env: 'CGR Staging',
    url: 'iceman-cgr-staging.azurewebsites.net/version',
  },
  {
    name: 'Banshee',
    env: 'Staging',
    url: 'banshee-live-functions-staging.azurewebsites.net/api/version',
  },
  {
    name: 'Legion',
    env: 'Staging',
    url: 'legion-live-functions-staging.azurewebsites.net/api/getversion',
  },
  {
    name: 'NightCrawler',
    env: 'US Staging',
    url: 'nightcrawler-live-functions-staging.azurewebsites.net/api/version',
  },
  {
    name: 'NightCrawler',
    env: 'FR Staging',
    url: 'nightcrawler-fr-functions-staging.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'US Staging',
    url: 'cyclops-live-functions-staging.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'FR Staging',
    url: 'cyclops-fr-functions-staging.azurewebsites.net/api/version',
  },
  {
    name: 'Cyclops',
    env: 'CGR Staging',
    url: 'cyclops-cgr-functions-staging.azurewebsites.net/api/version',
  },
];
export const backupEnvs = [
  {
    name: 'Boost',
    env: 'US Live Backup',
    url: 'wmp-dti-live-client-backup.azurewebsites.net/api/data/Version',
  },
  {
    name: 'Boost',
    env: 'FR Live Backup',
    url: 'boost-fr-backup.azurewebsites.net/api/data/Version',
  },
  {
    name: 'QuickSilver',
    env: 'US Live Backup',
    url: 'quicksilver-api-live-backup.azurewebsites.net/version',
  },
  {
    name: 'QuickSilver',
    env: 'FR Live Backup',
    url: 'quicksilver-fr-backup.azurewebsites.net/version',
  },
  {
    name: 'QuickSilver',
    env: 'CGR Live Backup',
    url: 'quicksilver-cgr-backup.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'US Live Backup',
    url: 'unified-api-live-backup.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'FR Live Backup',
    url: 'cypher-fr-backup.azurewebsites.net/version',
  },
  {
    name: 'Cypher',
    env: 'CGR Live Backup',
    url: 'cypher-cgr-backup.azurewebsites.net/version',
  },
];
