import React from 'react';

import classnames from 'classnames';
import { Row, Col, Form } from 'react-bootstrap';
import { useRecoilValue, useRecoilState } from 'recoil';

import { showTestCaptureIdState, testCaptureIdState } from '../services/Atoms';

const CaptureTestIdRow = () => {
  const [testCaptureId, setTestCaptureId] = useRecoilState(testCaptureIdState);
  const showTestCaptureId = useRecoilValue(showTestCaptureIdState);
  return (
    <Row
      className={classnames(
        'order-external-id-ref-row',
        showTestCaptureId || 'hidden'
      )}
    >
      <Col>
        <Form.Group controlId='Form.TestId'>
          <Form.Label>Test Capture ID</Form.Label>
          <Form.Control
            type='text'
            value={testCaptureId}
            placeholder='enter a new TestCaptureID like Vista.Everyman.NewTest2'
            onChange={(e) => setTestCaptureId(e.currentTarget.value)}
            data-nr-qb-testcaptureid=''
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default CaptureTestIdRow;
