import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Launcher from './views/Launcher';
import Versions from './views/Versions';
import './app.scss';

const App = () => (
  <div className='app'>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Launcher />} />
          <Route path='/versions' element={<Versions />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  </div>
);

export default App;
