import { useRecoilValue } from 'recoil';

import { renderLink } from '../../Constants';
import useGetQueryString from '../../hooks/useGetQueryString';
import useHostName from '../../hooks/useHostname';
import {
  circuitState,
  showStartSeasonPassActionButtonState,
  loyaltyState,
  cinemaState,
} from '../../services/Atoms';
// eslint-disable-next-line react/prop-types
const StartSeasonPassLink = () => {
  const hostname = useHostName();
  const circuit = useRecoilValue(circuitState);
  const loyalty = useRecoilValue(loyaltyState);
  const cinema = useRecoilValue(cinemaState);
  const showStartSeasonPassActionButton = useRecoilValue(
    showStartSeasonPassActionButtonState
  );

  const qsAsAdditionalParam = useGetQueryString(true);

  if (!cinema || !circuit || !showStartSeasonPassActionButton) return;
  const path = loyalty
    ? `${hostname}/api/startseasonpass/redirect?cinemaId=${cinema.externalId}`
    : `${hostname}/startseasonpass?cinemaId=${cinema.externalId}`;
  const url = `${path}${qsAsAdditionalParam}`;
  return renderLink(url, 'START SEASON PASS');
};

export default StartSeasonPassLink;
