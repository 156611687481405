import React from 'react';

import { Row, Col } from 'react-bootstrap';

import ConcessionsLandingPageLink from './ConcessionsLandingPageLink';
import ConfirmationLink from './ConfirmationLink';
import StartConcessionsLink from './StartConcessionsLink';
import StartGiftCardsLink from './StartGiftCardsLink';
import StartGiftMembershipLink from './StartGiftMembershipLink';
import StartLoyaltyUpgradeLink from './StartLoyaltyUpgradeLink';
import StartPhysicalGiftCardsLink from './StartPhysicalGiftCardsLink';
import StartRefundsLink from './StartRefundsLink';
import StartSeasonPassLink from './StartSeasonPassLink';
import StartSignUpLink from './StartSignUpLink';
import StartTicketingLink from './StartTicketingLink';
import StartVouchersLink from './StartVouchersLink';

// eslint-disable-next-line react/prop-types
const AllLinks = () => {
  return (
    <>
      <StartTicketingLink />
      <Row>
        <Col>
          <ConcessionsLandingPageLink />
        </Col>
        <Col>
          <StartConcessionsLink />
        </Col>
      </Row>
      <Row>
        <Col>
          <StartGiftCardsLink />
        </Col>
        <Col>
          <StartPhysicalGiftCardsLink />
        </Col>
      </Row>
      <Row>
        <Col>
          <StartGiftMembershipLink />
        </Col>
        <Col>
          <StartVouchersLink />
        </Col>
      </Row>
      <Row>
        <Col>
          <StartRefundsLink />
        </Col>
        <Col>
          <ConfirmationLink />
        </Col>
      </Row>
      <Row>
        <Col>
          <StartSignUpLink />
        </Col>
        <Col>
          <StartLoyaltyUpgradeLink />
        </Col>
      </Row>
      <StartSeasonPassLink />
    </>
  );
};

export default AllLinks;
