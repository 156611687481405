import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { circuitsState, errorsState } from '../services/Atoms';

const findDupe = (circuits, field, errors) => {
  var idArray = circuits.map((item) => item[field]);
  var dupeField = null;
  var hasDuplicate = idArray.some((item, idx) => {
    const isDupe = idArray.indexOf(item) != idx;
    if (isDupe) {
      dupeField = item;
    }
    return isDupe;
  });
  if (hasDuplicate) {
    const dupedCircuits = circuits.filter((x) => x[field] == dupeField);
    const c1 = dupedCircuits[0];
    const c2 = dupedCircuits[1];
    const error = `Multiple circuits with same ${field}: ${dupeField}. Name: ${c1.name} (${c1.boxOfficeCircuitId}) and Name:  ${c2.name} (${c2.boxOfficeCircuitId})`;
    errors.push(error);
  }
};

const useCheckCircuits = () => {
  const circuits = useRecoilValue(circuitsState);
  const setErrors = useSetRecoilState(errorsState);
  useEffect(() => {
    const errors = [];
    const buildErrors = () => {
      findDupe(circuits, 'id', errors);
      findDupe(circuits, 'hostname', errors);
      if (errors.length > 0) {
        setErrors(errors);
      }
    };
    if (circuits?.length > 0) {
      buildErrors();
    }
  }, [circuits, setErrors]);
};
export default useCheckCircuits;
