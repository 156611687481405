import { useRecoilValue } from 'recoil';

import { renderLink } from '../../Constants';
import useGetQueryString from '../../hooks/useGetQueryString';
import useHostName from '../../hooks/useHostname';
import {
  cinemaState,
  circuitState,
  showStartConcessionsActionButtonState,
  loyaltyState,
} from '../../services/Atoms';

// eslint-disable-next-line react/prop-types
const StartConcessionsLink = () => {
  const circuit = useRecoilValue(circuitState);
  const cinema = useRecoilValue(cinemaState);
  const loyalty = useRecoilValue(loyaltyState);
  const hostname = useHostName();
  const showStartConcessionsActionButton = useRecoilValue(
    showStartConcessionsActionButtonState
  );
  const qs = useGetQueryString();

  if (!cinema || !circuit || !showStartConcessionsActionButton) return;
  const path = loyalty
    ? `${hostname}/api/startconcessions/redirect/${cinema.id}`
    : `${hostname}/startconcessions/${cinema.id}`;
  const url = `${path}${qs}`;
  return renderLink(url, 'START CONCESSIONS');
};

export default StartConcessionsLink;
