import React from 'react';

import classnames from 'classnames';
import { Row, Col, Form } from 'react-bootstrap';
import { useRecoilValue, useRecoilState } from 'recoil';

import { showBookingRefState, bookingRefState } from '../services/Atoms';

const BookingRefRow = () => {
  const [bookingRef, setBookingRef] = useRecoilState(bookingRefState);
  const showBookingRef = useRecoilValue(showBookingRefState);
  return (
    <Row
      className={classnames('booking-ref-row my-3', showBookingRef || 'hidden')}
    >
      <Col>
        <Form.Group controlId='Form.BookingRef'>
          <Form.Label>Booking reference</Form.Label>
          <Form.Control
            type='text'
            value={bookingRef}
            placeholder='paste booking reference'
            onChange={(e) => setBookingRef(e.currentTarget.value)}
            data-nr-qb-bookingref=''
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default BookingRefRow;
