import { useEffect } from 'react';

import * as moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { boostEnvOptions, allScreenKey } from '../Constants';
import {
  envState,
  accessTokenState,
  circuitState,
  cinemaState,
  showStartTicketingActionButtonState,
  showStartConcessionsActionButtonState,
  showStartGiftCardsActionButtonState,
  showStartPhysicalGiftCardsActionButtonState,
  showStartGiftMembershipActionButtonState,
  showStartVouchersActionButtonState,
  showStartSeasonPassActionButtonState,
  movieState,
  sessionState,
  sessionsState,
  screenIdState,
  screensState,
} from '../services/Atoms';
import backend from '../services/RestUtilities';

const useFetchSessions = () => {
  const accessToken = useRecoilValue(accessTokenState);
  const env = useRecoilValue(envState);
  const circuit = useRecoilValue(circuitState);
  const cinema = useRecoilValue(cinemaState);
  const movie = useRecoilValue(movieState);
  const setSession = useSetRecoilState(sessionState);
  const setSessions = useSetRecoilState(sessionsState);
  const setScreens = useSetRecoilState(screensState);
  const setScreenId = useSetRecoilState(screenIdState);
  const setShowStartTicketingActionButton = useSetRecoilState(
    showStartTicketingActionButtonState
  );
  const setShowStartConcessionsActionButton = useSetRecoilState(
    showStartConcessionsActionButtonState
  );
  const setShowStartGiftCardsActionButton = useSetRecoilState(
    showStartGiftCardsActionButtonState
  );
  const setShowStartPhysicalGiftCardsActionButton = useSetRecoilState(
    showStartPhysicalGiftCardsActionButtonState
  );
  const setShowStartGiftMembershipActionButton = useSetRecoilState(
    showStartGiftMembershipActionButtonState
  );
  const setShowStartVouchersActionButton = useSetRecoilState(
    showStartVouchersActionButtonState
  );
  const setShowStartSeasonPassActionButton = useSetRecoilState(
    showStartSeasonPassActionButtonState
  );

  useEffect(() => {
    const fetchSessions = async () => {
      if (!movie || !circuit || !cinema) return;
      let platformVersionToUse;
      if (env === 'LOCAL' || env === 'DEV') {
        let splitString = circuit.platformVersion.split('-');
        platformVersionToUse = `${splitString[0]}-${splitString[1]}-Dev`;
      } else {
        platformVersionToUse = circuit.platformVersion;
      }
      const response = await backend.get(
        `${boostEnvOptions[env]}/api/data/Sessions/${cinema.id}/${movie.filmId}?platformVersion=${platformVersionToUse}`
      );
      if (response.ok) {
        let session = null;
        let screens = [];
        let sessions = response.content.filter(
          (x) => moment(x.date) > moment()
        );
        if (sessions.length > 0) {
          session = sessions[0];
          screens = [
            ...new Set(sessions.map((s) => s.screen).filter((s) => !!s)),
          ].sort((a, b) =>
            a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base',
            })
          );
        } else {
          sessions = null;
        }

        const screenId = allScreenKey;

        setSessions(sessions);
        setSession(session);
        setScreens(screens);
        setScreenId(screenId);
        setShowStartTicketingActionButton(true);
        setShowStartConcessionsActionButton(true);
        setShowStartGiftCardsActionButton(true);
        setShowStartPhysicalGiftCardsActionButton(true);
        setShowStartGiftMembershipActionButton(true);
        setShowStartVouchersActionButton(true);
        setShowStartSeasonPassActionButton(true);
      }
    };
    if (movie) {
      fetchSessions();
    }
  }, [
    cinema,
    circuit,
    env,
    movie,
    accessToken,
    setSessions,
    setSession,
    setScreens,
    setScreenId,
    setShowStartTicketingActionButton,
    setShowStartConcessionsActionButton,
    setShowStartGiftCardsActionButton,
    setShowStartPhysicalGiftCardsActionButton,
    setShowStartGiftMembershipActionButton,
    setShowStartVouchersActionButton,
    setShowStartSeasonPassActionButton,
  ]);
};
export default useFetchSessions;
