import { useRecoilValue } from 'recoil';

import { renderLink } from '../../Constants';
import useGetQueryString from '../../hooks/useGetQueryString';
import useHostName from '../../hooks/useHostname';
import {
  circuitState,
  externalOrderIdState,
  showOrderExternalIdState,
} from '../../services/Atoms';
// eslint-disable-next-line react/prop-types
const StartRefundsLink = () => {
  const hostname = useHostName();
  const circuit = useRecoilValue(circuitState);
  const externalOrderId = useRecoilValue(externalOrderIdState);
  const showOrderExternalId = useRecoilValue(showOrderExternalIdState);
  const qs = useGetQueryString();

  if (!circuit || !showOrderExternalId || !externalOrderId) {
    return;
  }
  const url = `${hostname}/refund/${externalOrderId}${qs}`;
  return renderLink(url, 'START REFUND');
};

export default StartRefundsLink;
