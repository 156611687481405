import { useRecoilValue } from 'recoil';

import { renderLink } from '../../Constants';
import useGetQueryString from '../../hooks/useGetQueryString';
import useHostName from '../../hooks/useHostname';
import {
  cinemaState,
  circuitState,
  showStartConcessionsActionButtonState,
} from '../../services/Atoms';

// eslint-disable-next-line react/prop-types
const ConcessionsLandingPageLink = () => {
  const circuit = useRecoilValue(circuitState);
  const cinema = useRecoilValue(cinemaState);
  const hostname = useHostName();
  const showStartConcessionsActionButton = useRecoilValue(
    showStartConcessionsActionButtonState
  );
  const qs = useGetQueryString();
  if (!cinema || !circuit || !showStartConcessionsActionButton) return;
  const url = `${hostname}/concessions/landing/${qs}`;
  return renderLink(url, 'KIOSK LANDING PAGE');
};

export default ConcessionsLandingPageLink;
