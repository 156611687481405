import 'whatwg-fetch';
export default class RestUtilities {
  static get(url) {
    return RestUtilities.request('GET', url);
  }

  static delete(url) {
    return RestUtilities.request('DELETE', url);
  }

  static put(url, data) {
    return RestUtilities.request('PUT', url, data);
  }

  static post(url, data) {
    return RestUtilities.request('POST', url, data);
  }

  static postFormData(url, data) {
    return RestUtilities.request('POST', url, data, true);
  }

  static request(method, url, data, isFormData) {
    let ok = false;
    let body = data;
    let statusCode = 200;
    let statusText = '';
    const headers = new Headers();
    headers.set('Accept', 'application/json');

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      headers.set('LauncherAccessToken', accessToken);
    }

    if (!isFormData) {
      headers.set('Content-Type', 'application/json');
      body = JSON.stringify(data);
    } else {
      //headers.set('Content-Type','multipart/form-data');
      body = data;
    }

    return fetch(url, { method, headers, body })
      .then((response) => {
        ok = response.ok;
        statusCode = response.status;
        statusText = response.statusText;
        const responseContentType = response.headers.get('content-type');
        if (
          responseContentType &&
          responseContentType.indexOf('application/json') !== -1
        ) {
          return response.json();
        }
        return response.text();
      })
      .then((responseContent) => {
        const response = {
          ok: ok,
          hasModelErrors: statusCode === 400,
          content: ok ? responseContent : null,
          errorContent: ok ? null : responseContent,
          status: statusCode,
          statusText: statusText,
        };
        return response;
      })
      .catch(() => {
        return {
          ok: false,
        };
      });
  }
}
