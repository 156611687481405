import { useRecoilValue } from 'recoil';

import { renderLink } from '../../Constants';
import useGetQueryString from '../../hooks/useGetQueryString';
import useHostName from '../../hooks/useHostname';
import {
  circuitState,
  showStartPhysicalGiftCardsActionButtonState,
} from '../../services/Atoms';

// eslint-disable-next-line react/prop-types
const StartPhysicalGiftCardsLink = () => {
  const circuit = useRecoilValue(circuitState);
  const hostname = useHostName();
  const showStartPhysicalGiftCardsActionButton = useRecoilValue(
    showStartPhysicalGiftCardsActionButtonState
  );
  const qs = useGetQueryString();

  if (!circuit || !showStartPhysicalGiftCardsActionButton) return;
  const path = `${hostname}/physicalgiftcards`;
  let url = `${path}${qs}`;
  return renderLink(url, 'START PHYSICAL GIFT CARDS');
};

export default StartPhysicalGiftCardsLink;
