import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { boostEnvOptions } from '../Constants';
import {
  envState,
  loadingState,
  accessTokenState,
  circuitsState,
  circuitState,
  cinemasState,
  cinemaState,
  showStartTicketingActionButtonState,
  showStartConcessionsActionButtonState,
  showStartGiftCardsActionButtonState,
  showStartPhysicalGiftCardsActionButtonState,
  showStartGiftMembershipActionButtonState,
  showStartVouchersActionButtonState,
  showStartSeasonPassActionButtonState,
  isTokenValidState,
} from '../services/Atoms';
import backend from '../services/RestUtilities';
const isInternalLauncher =
  window.location.host &&
  (window.location.host.toLowerCase() === 'localhost:3000' ||
    window.location.host.toLowerCase() ===
      'wmp-dti-launcher.azurewebsites.net');

const useFetchCircuits = () => {
  const accessToken = useRecoilValue(accessTokenState);
  const env = useRecoilValue(envState);
  const setLoading = useSetRecoilState(loadingState);
  const setCircuits = useSetRecoilState(circuitsState);
  const setCircuit = useSetRecoilState(circuitState);
  const setCinemas = useSetRecoilState(cinemasState);
  const setCinema = useSetRecoilState(cinemaState);
  const setAccessToken = useSetRecoilState(accessTokenState);
  const setIsTokenValid = useSetRecoilState(isTokenValidState);
  const setShowStartTicketingActionButton = useSetRecoilState(
    showStartTicketingActionButtonState
  );
  const setShowStartConcessionsActionButton = useSetRecoilState(
    showStartConcessionsActionButtonState
  );
  const setShowStartGiftCardsActionButton = useSetRecoilState(
    showStartGiftCardsActionButtonState
  );
  const setShowStartPhysicalGiftCardsActionButton = useSetRecoilState(
    showStartPhysicalGiftCardsActionButtonState
  );
  const setShowStartGiftMembershipActionButton = useSetRecoilState(
    showStartGiftMembershipActionButtonState
  );
  const setShowStartVouchersActionButton = useSetRecoilState(
    showStartVouchersActionButtonState
  );
  const setShowStartSeasonPassActionButton = useSetRecoilState(
    showStartSeasonPassActionButtonState
  );

  useEffect(() => {
    if (!accessToken && isInternalLauncher) return;
    // const fetchVersion = async () => {
    //   const url = `${envOptions[env]}/api/data/Version`;
    //   const response = await backend.get(url);
    //   if (response.ok) {
    //     const version = response.content;
    //     setVersion(version);
    //   }
    // };
    const fetchCircuits = async () => {
      setLoading(true);
      const response = await backend.get(
        `${boostEnvOptions[env]}/api/data/Circuits`
      );
      if (response.ok) {
        const circuits = response.content;
        if (circuits.length > 0) {
          const circuit = circuits[0];
          const cinemas = circuit.cinemas;
          let cinema = null;
          if (cinemas.length > 0) {
            cinema = cinemas[0];
          }
          setCircuits(circuits);
          setCircuit(circuit);
          setCinemas(cinemas);
          setCinema(cinema);
          setShowStartTicketingActionButton(false);
          setShowStartConcessionsActionButton(false);
          setShowStartGiftCardsActionButton(true);
          setShowStartPhysicalGiftCardsActionButton(true);
          setShowStartGiftMembershipActionButton(true);
          setShowStartVouchersActionButton(true);
          setShowStartSeasonPassActionButton(true);
          setIsTokenValid(true);
        }
      } else if (response.errorContent === 'Invalid token') {
        window.alert('Invalid Launcher token');
        setIsTokenValid(false);
        setAccessToken('');
      } else {
        window.alert(
          'Could not talk to cms or api, there is a configuration problem...'
        );
        setIsTokenValid(false);
        setAccessToken('');
      }
      setLoading(false);
    };
    if (env) {
      //fetchVersion();
      fetchCircuits();
    }
  }, [
    env,
    accessToken,
    setCircuits,
    setCircuit,
    setCinemas,
    setCinema,
    setLoading,
    setShowStartTicketingActionButton,
    setShowStartConcessionsActionButton,
    setShowStartGiftCardsActionButton,
    setShowStartPhysicalGiftCardsActionButton,
    setShowStartGiftMembershipActionButton,
    setShowStartVouchersActionButton,
    setShowStartSeasonPassActionButton,
    setAccessToken,
    setIsTokenValid,
  ]);
};
export default useFetchCircuits;
