import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';

const TokenInput = ({ accessToken, setAccessToken, setIsTokenValid }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (accessToken) {
      localStorage.removeItem('accessToken');
      setIsTokenValid(false);
      setAccessToken('');
    } else {
      localStorage.setItem('accessToken', inputValue);
      setAccessToken(inputValue);
    }
    setInputValue('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className='mb-1' controlId='accessToken'>
        <Col sm={9} className='d-flex'>
          {!accessToken && (
            <Form.Control
              type='text'
              placeholder='Enter Launcher token'
              value={inputValue}
              onChange={handleInputChange}
              size='sm'
              className='token-input'
            />
          )}
          <Button
            type='submit'
            variant={accessToken ? 'light' : 'primary'}
            disabled={!inputValue && !accessToken}
            size='sm'
          >
            {accessToken ? 'Reset Token' : 'Set'}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

TokenInput.propTypes = {
  accessToken: PropTypes.string.isRequired,
  setAccessToken: PropTypes.func.isRequired,
  setIsTokenValid: PropTypes.func.isRequired,
};

export default TokenInput;
