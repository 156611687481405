import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import useGetQueryString from '../../hooks/useGetQueryString';
import useHostName from '../../hooks/useHostname';
import {
  cinemaState,
  sessionState,
  loyaltyState,
  showStartTicketingActionButtonState,
} from '../../services/Atoms';

// eslint-disable-next-line react/prop-types
const RenderStartTicketingLink = () => {
  const session = useRecoilValue(sessionState);
  const cinema = useRecoilValue(cinemaState);
  const loyalty = useRecoilValue(loyaltyState);
  const hostname = useHostName();
  const showStartTicketingActionButton = useRecoilValue(
    showStartTicketingActionButtonState
  );
  const qs = useGetQueryString();

  if (!session || !cinema || !showStartTicketingActionButton) return;
  const path = loyalty
    ? `${hostname}/api/startticketing/redirect/${cinema.externalId}/${session.externalId}`
    : `${hostname}/startticketing/${cinema.externalId}/${session.externalId}`;
  let url = `${path}${qs}`;

  return (
    <Row className='start-row'>
      <Col xs={{ span: 8, offset: 2 }} className='text-center'>
        <a
          className='start ticketing'
          href={url}
          data-nr-qb-submit=''
          rel='noopener noreferrer'
          target='_blank'
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path fill='none' d='M0,0H24V24H0Z' />
            <path
              fill='currentColor'
              d='M22,10V6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6v4a2,2,0,0,1,0,4v4a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V14a2,2,0,0,1,0-4ZM20,8.54a4,4,0,0,0,0,6.92V18H4V15.46A4,4,0,0,0,6,12,4,4,0,0,0,4,8.54V6H20ZM10.55,8,9.61,9l3.05,3L9.61,15.06l.94.94,4-4Z'
            />
          </svg>
        </a>
      </Col>
    </Row>
  );
};

export default RenderStartTicketingLink;
