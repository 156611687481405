import { renderLink } from '../../Constants';
import useGetQueryString from '../../hooks/useGetQueryString';
import useHostName from '../../hooks/useHostname';

// eslint-disable-next-line react/prop-types
const StartSignUpLink = () => {
  const hostname = useHostName();
  const qs = useGetQueryString();

  const path = `${hostname}/signup`;
  let url = `${path}${qs}`;
  return renderLink(url, 'START TIERED SIGNUP');
};

export default StartSignUpLink;
