import React from 'react';

import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import TokenInput from './TokenInput';

import {
  allScreenKey,
  boostEnvOptions,
  sourceOptions,
  getEnvNightRiderAttr,
  getSourceNightRiderAttr,
} from '../Constants';
import {
  circuitsState,
  circuitState,
  cinemasState,
  cinemaState,
  moviesState,
  movieState,
  sessionsState,
  sessionState,
  screensState,
  screenIdState,
  envState,
  sourceState,
  loyaltyState,
  addCinemaIdState,
  addCircuitIdState,
  showBookingRefState,
  showOrderExternalIdState,
  showTestIdState,
  showTestCaptureIdState,
  accessTokenState,
  isTokenValidState,
} from '../services/Atoms';

const LogoRow = () => {
  const isInternalLauncher =
    window.location.host &&
    (window.location.host.toLowerCase() === 'localhost:3000' ||
      window.location.host.toLowerCase() ===
        'wmp-dti-launcher.azurewebsites.net');
  const navigate = useNavigate();
  const setCircuits = useSetRecoilState(circuitsState);
  const setCircuit = useSetRecoilState(circuitState);
  const setCinemas = useSetRecoilState(cinemasState);
  const setCinema = useSetRecoilState(cinemaState);
  const setMovies = useSetRecoilState(moviesState);
  const setMovie = useSetRecoilState(movieState);
  const setSessions = useSetRecoilState(sessionsState);
  const setSession = useSetRecoilState(sessionState);
  const setScreens = useSetRecoilState(screensState);
  const setScreenId = useSetRecoilState(screenIdState);
  const [env, setEnv] = useRecoilState(envState);
  const [source, setSource] = useRecoilState(sourceState);
  const [loyalty, setLoyalty] = useRecoilState(loyaltyState);
  const [addCinemaId, setAddCinemaId] = useRecoilState(addCinemaIdState);
  const [addCircuitId, setAddCircuitId] = useRecoilState(addCircuitIdState);
  const [showBookingRef, setShowBookingRef] =
    useRecoilState(showBookingRefState);
  const [showOrderExternalId, setShowOrderExternalId] = useRecoilState(
    showOrderExternalIdState
  );
  const [showTestId, setShowTestId] = useRecoilState(showTestIdState);
  const [showTestCaptureId, setShowTestCaptureId] = useRecoilState(
    showTestCaptureIdState
  );
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const setIsTokenValid = useSetRecoilState(isTokenValidState);

  const handleSourceChange = (source) => {
    setSource(source);
  };

  const handleEnvChange = (env) => {
    setEnv(env);
    setCircuits([]);
    setCircuit(null);
    setCinemas([]);
    setCinema(null);
    setMovies([]);
    setMovie(null);
    setSessions([]);
    setSession(null);
    setScreens([]);
    setScreenId(allScreenKey);
    setAddCircuitId(env === 'LOCAL' || env === 'DEV' || env == 'STAGING');
  };

  const renderEnvOptions = () => {
    if (isInternalLauncher) {
      return Object.keys(boostEnvOptions).map((option) => (
        <Form.Check
          key={option}
          name='groupOptions1'
          label={option}
          type='radio'
          id={option}
          onChange={() => handleEnvChange(option)}
          checked={option === env}
          {...getEnvNightRiderAttr(option)}
        />
      ));
    } else {
      return Object.keys(boostEnvOptions).map((option) => {
        if (option == 'UAT' || option == 'LIVE')
          return (
            <Form.Check
              key={option}
              name='groupOptions1'
              label={option}
              type='radio'
              id={option}
              onChange={() => handleEnvChange(option)}
              checked={option === env}
              {...getEnvNightRiderAttr(option)}
            />
          );
      });
    }
  };

  return (
    <Row className='logo-row'>
      <Col sm={3} className='text-center image-container'>
        <Image src='/images/boxoffice.png' />
      </Col>
      <Col sm={9}>
        <Row>
          <Col sm={3}>
            <Form.Label>Environment </Form.Label>
            <Form.Group controlId='Form.ControlsCheck'>
              {renderEnvOptions()}
            </Form.Group>
          </Col>
          <Col sm={3}>
            {isInternalLauncher && (
              <>
                <Form.Label>Salestracker Source</Form.Label>
                <Form.Group controlId='Form.ControlsCheck'>
                  {Object.keys(sourceOptions).map((s) => (
                    <Form.Check
                      key={s}
                      name='groupOptions2'
                      label={s}
                      type='radio'
                      id={s}
                      onChange={() => handleSourceChange(s)}
                      checked={source === s}
                      {...getSourceNightRiderAttr(s)}
                    />
                  ))}
                </Form.Group>
              </>
            )}
          </Col>
          <Col sm={3}>
            {isInternalLauncher && (
              <>
                <Form.Label>Options</Form.Label>
                <Form.Check
                  name='loyalty'
                  label='Loyalty Login'
                  type='checkbox'
                  checked={loyalty}
                  onChange={() => setLoyalty(!loyalty)}
                  data-nr-qb-loyaltylogin=''
                />
                <Form.Check
                  name='showBookingRef'
                  label='Booking Ref'
                  type='checkbox'
                  checked={showBookingRef}
                  onChange={() => setShowBookingRef(!showBookingRef)}
                  data-nr-qb-showbookingref=''
                />
                <Form.Check
                  label='OrderExternalId'
                  type='checkbox'
                  checked={showOrderExternalId}
                  onChange={() => setShowOrderExternalId(!showOrderExternalId)}
                  data-nr-qb-showorderexternalid=''
                />
                <Form.Check
                  label='TestId'
                  type='checkbox'
                  checked={showTestId}
                  onChange={() => setShowTestId(!showTestId)}
                  data-nr-qb-showtestid=''
                />
                <Form.Check
                  label='TestCaptureId'
                  type='checkbox'
                  checked={showTestCaptureId}
                  onChange={() => setShowTestCaptureId(!showTestCaptureId)}
                  data-nr-qb-showtestcaptureid=''
                />
                <Form.Check
                  label='Add CinemaId'
                  type='checkbox'
                  checked={addCinemaId}
                  onChange={() => setAddCinemaId(!addCinemaId)}
                  data-nr-qb-addcinemaid=''
                />
                <Form.Check
                  label='Add CircuitId'
                  type='checkbox'
                  checked={addCircuitId}
                  onChange={() => setAddCircuitId(!addCircuitId)}
                  data-nr-qb-addcinemaid=''
                />
              </>
            )}
          </Col>
          <Col sm={3}>
            {isInternalLauncher && (
              <>
                <Button
                  type='submit'
                  variant={'primary'}
                  size='sm'
                  className='mb-2'
                  onClick={() => navigate('/versions')}
                >
                  Check Versions
                </Button>
                <TokenInput
                  accessToken={accessToken}
                  setAccessToken={setAccessToken}
                  setIsTokenValid={setIsTokenValid}
                />
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LogoRow;
